@import "minima";

.red {
  color: red;
}

.post-title {
  margin-bottom: 12px;
}

.post-content {

  h2 {
    line-height: 38px;
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 15px;
  }

  h4 {
    font-size: 22px;
  }

  /* just the inline code */
  p, ul, ol, code.highlighter-rouge {
    font-size: 1.125rem;
    line-height: 1.7;
    margin-bottom: 1rem;
  }

  code {
    font-size: inherit
  }

  .code-snippet {
    font-size: 14px;
  }

  .code-toolbar {
    margin-bottom: 1.82813rem;
  }

  p + .code-toolbar {
    margin-top: -0.6rem;
  }

  .highlight {
    line-height: 18px;
  }

  figure {
    margin: 0
  }

  figcaption {
    text-align: center;
    font-size: .9rem;
    font-style: italic;
    color: light-grey;
  }

  aside {
    margin: 15px 0;
    p {
      font-size: inherit;
      line-height: inherit;
      margin: 0;
    }
  }
}

.cover-image {
  text-align: center;
}

.toolbar-item button {
  padding: 4px 7px !important;
  font-size: 16px !important;
}

.post img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Toast {
  max-width: 100% !important;
}

.post-list {
  .post-link {
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
  }

  li {
    margin-bottom: 14px;
  }
}

.convertkit-form {
  .formkit-form[data-uid="af03941f9e"] {
    margin-bottom: 30px;

    div[data-style="minimal"] {
      padding: 25px;
    }

    .formkit-header {
      margin-bottom: 10px;
    }

    .formkit-subheader {
      margin: 0;

      p {
        display: none !important;
      }
    }

    .formkit-guarantee { display: none; }
    .formkit-powered-by { color: #fff !important; text-align: left !important; }
  }
}

footer .svg-icon {
  margin-right: 5px;
  padding-right: 0px;
}

footer .follow-links {
  list-style-type: none;
  margin-left: 0px;
}

.orange {
  fill: #f66a0a;
}

.twitter-color {
  fill: #00acee;
}

.with-linesno {
  pre {
    padding: 0;
  }

  .code {
    padding: 8px 8px;
  }

  .gutter {
    width: 30px;
    color: #aaa;
    text-align: right;
    padding: 8px 5px 8px 8px;
    border-right: 1px solid #ccc;
  }
}

.tutorial-steps {
  counter-reset: item;
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 10px;

  li {
    display: block;
    padding: 5px 0 5px 30px;
    position: relative
  }
  li:hover {
    background-color: #f1f1f1;
  }
  li:before {
      content: counter(item) "  ";
      counter-increment: item;
      font-weight: bold;
      margin-right: 8px;
      width: 20px;
      text-align: right;

      position: absolute;
      left: 0;
  }
}

.comparison-table {
  td {
    vertical-align: top;
  }
}

.list-pros {
  list-style-type: none;
  margin-left: 0;
  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 2px;
  }
  li:before {
      content: "✅";
      position: absolute;
      left: 0;
  }
}

.list-cons {
  list-style-type: none;
  margin-left: 0;

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 2px;
  }

  li:before {
      content: "❌";
      margin-right: 5px;
      position: absolute;
      left: 0;
  }
}

.progress {
  background: linear-gradient(to right, #3391fc var(--scroll), transparent 0);
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
  height: 4px;
  z-index: 1;
}

table.conda-cheatsheet {
  font-size: 13px;
  line-height: 20px;
  border: 0;

  td {
    vertical-align: top;
    border: 1px solid #434343;
  }

  tr {
    background: none;
  }

  td.command-category {
    background: #434343;
    color: #fff;
  }

  .definition {
    background: #fff2cc;
  }

  .example {
    background: #d9ead3
  }

  .cheatsheet-note td {
    padding: 3px;
    border-width: 1px 0 0 0;
    font-size: 11px;
  }
}

/* Custom pages */
.page-about {
  h3 {
    margin: 12px 0 10px 0;
  }

  p {
    font-size: 18px;
  }


  @media only screen  and (min-width : 1224px) {
    img {
      float: right;
      max-width: 300px;
      margin-left: 20px;
    }
  }
}

.python-dependency-management-table {
  margin-bottom: 30px;

  table {
    margin-bottom: 5px;
  }

  tr td {
    text-align: center;;
  }

  tr td:first-child {
    text-align: right;
    padding-left: 5px;
  }

  .table-notes p {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }
}
